import request from '@/utils/request'


// 查询账户类型（店铺）列表
export function listShopAccount(query) {
  return request({
    url: '/shop/shop/account/list',
    method: 'get',
    params: query
  })
}

// 查询账户类型（店铺）分页
export function pageShopAccount(query) {
  return request({
    url: '/shop/shop/account/page',
    method: 'get',
    params: query
  })
}

// 查询账户类型（店铺）详细
export function getShopAccount(data) {
  return request({
    url: '/shop/shop/account/detail',
    method: 'get',
    params: data
  })
}

// 新增账户类型（店铺）
export function addShopAccount(data) {
  return request({
    url: '/shop/shop/account/add',
    method: 'post',
    data: data
  })
}

// 修改账户类型（店铺）
export function updateShopAccount(data) {
  return request({
    url: '/shop/shop/account/edit',
    method: 'post',
    data: data
  })
}

// 删除账户类型（店铺）
export function delShopAccount(data) {
  return request({
    url: '/shop/shop/account/delete',
    method: 'post',
    data: data
  })
}
